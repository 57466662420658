const adminFeeTable = [
  [5, 0.5],
  [20, 0.99],
  [50, 1.99],
  [75, 2.99],
  [100, 3.99],
  [125, 4.99],
  [150, 6.99],
  [200, 7.99],
];
const calculateAdminFee = (price, adminFeeOnly = false) => {
  if (price > 200) {
    if (adminFeeOnly) return 9.99;

    return price + 9.99;
  }

  const adminFee =
    adminFeeTable.find(([threshold]) => price <= threshold)?.[1] || 0;
  if (adminFeeOnly) return Number(adminFee.toFixed(2));

  return Number((price + adminFee).toFixed(2));
};

export const showEstimated = ({ finalOrderAmount, price, totalAdminFee }) => {
  const estimatedAdminFee = calculateAdminFee(finalOrderAmount + price, true);

  if (estimatedAdminFee > totalAdminFee) {
    return `+ Est. £${Number(estimatedAdminFee - totalAdminFee).toFixed(
      2,
    )} admin fee*`;
  }

  return null;
};
